import React from 'react';
import { motion } from 'framer-motion';
import Header from "../components/Header";
import Footer from "../components/Footer";

function History() {
  return (
    <>
      <Header />
      <motion.div
        className="flex items-center justify-center min-h-screen bg-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-gray-700 p-8 rounded-lg shadow-md max-w-2xl">
          <h2 className="text-3xl text-white font-semibold mb-4 text-center">History</h2>
          <p className="text-center text-gray-200">
            Journey of HESPRO Sports.

            HESPRO Sports:
            A Legacy of Excellence started 2018 in the name of
            ( Happy Sai Sports Academy ) has been a key player in sports, focusing on Coaching, Training, and Multi-sport Ground development.With rich experience of more then 6 years HESPRO Sports is evolving into a Sports Management Company, set to organize 50+ tournaments across various sports in 2025, boosting competitive sports.
<br/>
            Milestones and Goals: Since inception in 2018 till now we did

            Training and facility development.
<br/>
            Now in 2024: Expanded to sports management area

            and planned more then 50+ tournaments in 2025.
<br/>
            Future Ambitions: Create national and international opportunities for athletes.

            HESPRO SPORTS is committed to nurturing talent and enhancing sports infrastructure.
          </p>
        </div>
      </motion.div>
      <Footer />
    </>
  );
}

export default History;
